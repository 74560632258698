import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";

import { RegioluxArrowIcon, RegioluxResetIcon } from "@/icons/RegioluxIcons";

export function CustomSubconfigurationResetButton({
  onResetClicked,
  label,
}: {
  onResetClicked: () => void;
  label: string;
}): JSX.Element {
  return (
    <Button
      variant="text"
      onClick={onResetClicked}
      startIcon={<RegioluxResetIcon />}
      sx={{ height: "auto", justifyContent: "left" }}
    >
      {label}
    </Button>
  );
}

export function CustomSubconfigurationPreviousButton({
  onPreviousClicked,
  previousDisabled,
}: {
  onPreviousClicked(): void;
  previousDisabled: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      disabled={previousDisabled}
      onClick={onPreviousClicked}
      startIcon={
        <RegioluxArrowIcon
          type="short"
          direction="left"
          style={{ fontSize: "0.8rem" }}
        />
      }
    >
      {t("Translations:previous_Configuration")}
      <br />
      {t("Custom:previous_Configuration")}
      <br />
      {t("ConfigurationRenderer:previous_Configuration")}
    </Button>
  );
}

export function CustomSubconfigurationNextButton({
  onNextClicked,
  nextDisabled,
}: {
  onNextClicked(): void;
  nextDisabled: boolean;
}): JSX.Element {
  const { t } = useTranslation();

  return (
    <Button
      disabled={nextDisabled}
      onClick={onNextClicked}
      endIcon={
        <RegioluxArrowIcon
          type="short"
          direction="right"
          style={{ fontSize: "0.8rem" }}
        />
      }
    >
      {t("next_Configuration")}
    </Button>
  );
}
